import React from 'react'

import ErrorPage from '../../system/ErrorPage'

export interface Lead {
  email_provider: string // eslint-disable-line camelcase
  mobile_device: string // eslint-disable-line camelcase
  firstName: string
  lastName: string
  fullName: string
  email: string
  country: string
  phone: string
  companyName: string
  leadRecordId?: string
  discountCode?: string
  howHeard: string
  isHighDelegator: boolean
  isTeam: boolean
  isUnsure: boolean
}

const Confirmation = () => {
  // page no longer used, but there's a dangling reference in Contentful somewhere, so we still need to handle it
  return <ErrorPage />
}

export default Confirmation
