import styled, { css } from 'styled-components'

import { mobile, tablet } from '../../mixins'
import _Container from '../../modules/Container'

export const Container = styled(_Container)`
  align-items: center;
  text-align: center;
  margin-top: 128px;
  margin-bottom: 192px;
  max-width: 720px;

  ${tablet(css`
    margin: 120px 40px 150px;
  `)}

  ${mobile(css`
    margin: 80px 24px;
  `)}
`
