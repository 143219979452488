import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import Page from '../../components/layout/Page'
import { Subtitle } from '../../components/refer/styled'
import SmallButtonPrimary from '../buttons/SmallButtonPrimary'

const Outer = styled.div`
  padding: 240px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ErrorPage = () => (
  <Page
    head={{
      title: 'Oops',
    }}
  >
    <Outer>
      <Subtitle>{'Oops, something went wrong 🙈'}</Subtitle>
      <SmallButtonPrimary onClick={() => navigate('/')}>
        {'Back to Home'}
      </SmallButtonPrimary>
    </Outer>
  </Page>
)

export default ErrorPage
