import styled, { css } from 'styled-components'

import { mobile } from '../../mixins'
import { Color } from '../../theme'
import {
  Heading1,
  SubheadRegular,
  Heading3,
  heading3Style,
  captionRegularStyle,
  calloutSemiboldStyle,
  CalloutRegular,
  captionSemiboldStyle,
} from '../../typography'

export const Heading = styled(Heading1)`
  text-align: center;

  ${mobile(css`
    ${heading3Style({ noColor: true, noResponsive: true })}
    margin-bottom: 8px;
  `)}
`

export const Subhead = styled(SubheadRegular)`
  margin-top: 24px;
  margin-bottom: 72px;
  color: ${Color.GRAY_7};

  b {
    ${heading3Style({ noColor: true })}
  }

  ${mobile(css`
    ${captionRegularStyle({ noColor: true, noResponsive: true })}
    color: ${Color.GRAY_7};
    margin-bottom: 24px;

    b {
      ${captionSemiboldStyle({ noColor: true })}
    }
  `)}
`

export const Subtitle = styled(Heading3)`
  text-align: center;
  margin-bottom: 14px;

  ${mobile(css`
    ${calloutSemiboldStyle({ noColor: true, noResponsive: true })}
  `)}
`

export const Disclaimer = styled(CalloutRegular)`
  margin-top: 72px;
  color: ${Color.GRAY_7};

  ${mobile(css`
    margin-top: 36px;
  `)}
`
