import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import { mobile } from '../../mixins'
import { Color } from '../../theme'
import {
  calloutRegularStyle,
  calloutSemiboldStyle,
  bodySemiboldStyle,
} from '../../typography'

const BORDER_RADIUS = '12px'
const FONT_SIZE = '16px'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 372px;
  margin: 0 auto 0;
  max-width: 100%;

  ${mobile(css`
    width: 327px;
  `)}
`

interface InputProps {
  hasError: boolean
}

export const Input = styled.input<InputProps>`
  ${calloutRegularStyle({ noColor: true, noResponsive: true })}
  font-size: ${FONT_SIZE};
  line-height: 165%;
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  padding: 15px 16px;
  background: ${Color.GRAY_2};
  margin-top: 10px;
  box-shadow: ${(props) =>
    props.hasError ? 'inset 0 0 0 2px ' + Color.FLAMINGO_6 : 'none'};

  &::placeholder {
    color: ${Color.GRAY_6};
  }

  &:focus {
    background-color: ${Color.WHITE};
    box-shadow: inset 0 0 0 2px ${Color.PURPLE_4};
    -webkit-appearance: none;
  }

  ${mobile(css`
    height: 36px;
    line-height: 160%;

    &:focus {
      background-color: ${Color.WHITE};
      border: 0.2px solid ${Color.PURPLE_4};
    }
  `)}
`

export const Button = styled.button`
  ${bodySemiboldStyle({ noColor: true, noResponsive: true })}
  margin: 24px auto 0;
  text-align: center;
  padding: 13px 0;
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  height: 55px;
  color: ${Color.WHITE};
  width: 372px;

  &:hover {
    background-color: ${Color.PURPLE_6};
  }

  ${mobile(css`
    width: 327px;
    height: 44px;
    padding: 9px 0;
    margin: 16px auto 0;
  `)}
`

const Label = styled.label`
  display: flex;
  margin-top: 10px;
  select {
    flex: 1;
    border-style: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    color: ${Color.BLACK};
    cursor: pointer;
    background: ${Color.GRAY_2};
    font-weight: 500;
    font-size: ${FONT_SIZE};
    line-height: 21px;
    letter-spacing: -0.01em;
    padding: 14px;
    padding-right: 36px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    transition: box-shadow 200ms ease, border-color 200ms ease;
    max-width: 100%;
  }

  &::after {
    content: '▾';
    position: absolute;
    top: 50%;
    right: 14px;
    line-height: 21px;
    margin-top: -11px;
  }

  ${mobile(css`
    height: 36px;

    select {
      padding: 5px 12px;
    }
  `)}
`
const Span = styled.span`
  ${calloutSemiboldStyle({ noColor: true, noResponsive: true })}
  color: ${Color.FLAMINGO_6};
  margin-top: 6px;
  text-align: left;
`

export const SectionContainer = styled.div`
  margin-bottom: 72px;

  ${mobile(css`
    margin-bottom: 36px;
  `)}
`

interface SelectInputProps extends ComponentProps<'select'> {
  onChange: (e: any) => void
  options: {
    name?: string
    value: string
  }[]
}

export const SelectInput = ({
  name,
  placeholder,
  options,
  defaultValue,
  className: _className,
  onChange,
  ...props
}: SelectInputProps): JSX.Element => (
  <Label>
    <select
      defaultValue={defaultValue || ''}
      name={name}
      onChange={onChange}
      {...props}
    >
      <option value={''} disabled>
        {placeholder}
      </option>
      {options.map(({ name, value }) => (
        <option key={value} value={value}>
          {name ?? value}
        </option>
      ))}
    </select>
  </Label>
)

type InputWithErrorProps = ComponentProps<typeof Input> & {
  errors: Record<string, string>
}

export const InputWithError = ({
  name,
  hasError,
  errors,
  ...props
}: InputWithErrorProps): JSX.Element => (
  <>
    <Input name={name} hasError={hasError} {...props} />
    {hasError && name && <Span>{errors[name]}</Span>}
  </>
)
