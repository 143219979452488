import styled from 'styled-components'

import { mobile } from '../../components/mixins'
import { Color } from '../../components/theme'
import { calloutSemiboldStyle } from '../../components/typography'

import type { ButtonProps } from './shared'

const SmallButtonPrimary = styled.button<ButtonProps>`
  ${calloutSemiboldStyle({ noColor: true })}

  // don't let responsive typography overwrite padding
  padding: 0 16px !important;

  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  box-shadow: none;
  color: ${({ disabled }) => (disabled ? Color.GRAY_6 : Color.WHITE)};

  ${({ disabled }) =>
    disabled
      ? `
      background-color: ${Color.GRAY_1};
      cursor: default;
    `
      : `
      // enable hover style only if button is not disabled
      &:hover {
        background-color: ${Color.PURPLE_6};
      }
    `}

  ${mobile(`
    width: 100%;
  `)}

  p {
    color: ${({ disabled }) => (disabled ? Color.GRAY_6 : Color.WHITE)};
  }
`

export default SmallButtonPrimary
