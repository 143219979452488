import styled from 'styled-components'

interface Props {
  src: string
}

export const Image = styled.img<Props>`
  width: 375px;
  height: 160px;
  align-self: center;
`
